:root {
  --backgorund-page: #131314;
  --backgorund-content: #212124;
  --primary: #d97e09;
  --primary-hover: #ec8907;
  --primary-active: #c5730a;

  --text-1: #fff;
  --text-2: #fffc;
  --text-3: #b2b2b2;

  --padding-page: 20px;
  --header-height: 120px;
  --max-width-page: 768px;
}

@font-face {
  font-family: RetroGaming;
  src: url(/src/assets/RetroGaming.ttf);
  font-weight: 400;
  font-style: normal;
}

* {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
  scroll-behavior: smooth;
  background: var(--backgorund-page);
}

::-webkit-scrollbar {
  width: 14px;
  border-radius: 15px;
  background-color: var(--backgorund-page);
  cursor: default;
}

::-webkit-scrollbar-thumb {
  background-color: var(--backgorund-content);
  border-radius: 30px;
  border: 4px solid var(--backgorund-page);
  cursor: default;
}
::-webkit-scrollbar-button {
  display: none;
}

body {
  //background: var(--backgorund-page);
  background: linear-gradient(133deg, var(--backgorund-page) 0%, var(--backgorund-page) 48%, var(--backgorund-content) 100%);
  font-family: "RetroGaming", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - env(safe-area-inset-bottom));
  overflow-y: auto;
  overflow-x: hidden;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-page);
  .logo {
    display: flex;
    gap: 30px;
    align-items: center;
    border-radius: 16px;
    &-title {
      color: var(--text-1);
      font-size: 48px;
      &-change {
        display: inline-flex;
        width: 110px;
      }
      &-crocs {

      }
    }
    img {
      width: 80px;
      height: 80px;
      border-radius: 16px;
    }
  }
  .links {
    display: flex;
    gap: 30px;
    a {
      color: #fff;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
  @media (max-width: 768px) {
    .logo {
      gap: 16px;
      &-title {
        font-size: 18px;
        line-height: 24px;
        &-change {
          width: 45px;
        }
        &-crocs {

        }
      }
      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }
    }
    .links {
      gap: 16px;
      a {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

}

.top-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  position: relative;
  width: calc(100% - (var(--padding-page) * 2));
  text-align: center;
  padding: 0 var(--padding-page);
  margin: 0 auto;
  @supports (min-height: -webkit-fill-available) and (-webkit-touch-callout: none) {
    min-height: calc(-webkit-fill-available - var(--header-height));
  }
  @supports(min-height: 100dvh) {
    min-height: calc(100dvh - var(--header-height));
  }
  h1 {
    color: var(--text-1);
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 20px;
    z-index: 2;
    max-width: var(--max-width-page);
    span {
        color: var(--primary);
    }
  }
  p {
    color: var(--text-2);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px;
    z-index: 2;
    max-width: var(--max-width-page);
  }
  .play {
    text-decoration: unset;
    border: unset;
    cursor: pointer;
    background: var(--primary);
    border-radius: 71px;
    box-shadow: 0 2px 72.7px var(--primary-active);
    color: #fff;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    height: 80px;
    margin-top: 36px;
    max-width: 471px;
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    animation: pulsePlay 1s linear infinite;
    @-webkit-keyframes "pulsePlay" {
      0% {
        box-shadow: 0 0 20px 0 #d97e0929;
      }
      50% {
        box-shadow: 0 0 20px 5px #d97e09;
      }
      100% {
        box-shadow: 0 0 20px 0 #d97e0929;
      }
    }
    &:hover {
      background: var(--primary-hover);
      opacity: 0.9;
    }
    &:active {
      background: var(--primary-active);
      opacity: 0.9;
    }
  }
  .crocs {
    position: absolute;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: var(--backgorund-content);
    animation: pulseCrocs 1s linear infinite;
    @-webkit-keyframes "pulseCrocs" {
      0% {
        transform: scale(0.9);
      }
      70% {
        transform: scale(1);
      }
      100% {
        transform: scale(0.9);
      }
    }

    img {
      width: inherit;
      height: inherit;
      border-radius: 50%;
    }
    &.crocs-1 {
      top: 5%;
      left: 5%;
      rotate: 315deg;
      zoom: 1.2;
      animation-delay: 0ms;
      background: darkkhaki;
    }
    &.crocs-2 {
      top: 32%;
      left: 10%;
      rotate: 222deg;
      zoom: .9;
      animation-delay: 100ms;
      background: dimgrey;
    }
    &.crocs-3 {
      top: 12%;
      left: 33%;
      rotate: 111deg;
      zoom: 1.1;
      animation-delay: 200ms;
      background: cadetblue;
    }
    &.crocs-4 {
      top: 13%;
      right: 8%;
      rotate: 66deg;
      zoom: 1.3;
      animation-delay: 300ms;
      background: steelblue;
    }
    &.crocs-5 {
      top: 39%;
      right: 14%;
      rotate: 136deg;
      zoom: 1.0;
      animation-delay: 400ms;
      background: rosybrown;
    }
    &.crocs-6 {
      top: 1%;
      left: 67%;
      rotate: 0deg;
      zoom: 1.5;
      animation-delay: 600ms;
    }
    &.crocs-7 {
      top: 68%;
      right: 7%;
      rotate: 49deg;
      zoom: 1.3;
      animation-delay: 500ms;
      background: mediumseagreen;
    }
    &.crocs-8 {
      top: 81%;
      left: 9%;
      rotate: 21deg;
      zoom: 1.4;
      animation-delay: 700ms;
      background: darkorange;
    }
    &.crocs-9 {
      top: 79%;
      left: 54%;
      rotate: 309deg;
      zoom: 1.1;
      animation-delay: 800ms;
      background: rebeccapurple;
    }
  }
}

.roadmap {
  max-width: var(--max-width-page);
  padding: 0 var(--padding-page);
  margin: 60px auto 40px;
  width: calc(100% - (var(--padding-page) * 2));
  h2 {
    text-align: center;
    color: var(--text-1);
    font-size: 32px;
    line-height: 48px;
    @media (max-width: 768px) {
      text-align: left;

    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-item {
      display: flex;
      align-items: center;
      border-radius: 16px;
      padding: 12px 32px;
      background: var(--backgorund-content);
      gap: 40px;
      &-phase {
        color: var(--text-3);
        width: 115px;
      }
      &-values {
        position: relative;
        width: 100%;
        color: var(--text-1);
        ul {
          padding: 0 0 0 18px;
        }
        li {
          margin-bottom: 6px;
        }
        span {
          color: var(--primary);
        }
        li::marker {
          color: var(--primary);
        }
      }
      &-image-1 {
        height: 150px;
        position: absolute;
        bottom: -12px;
        right: -25px;
        transform: rotateY(186deg);
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-image-2 {
        height: 80px;
        position: absolute;
        right: 0;
        top: calc(50% - 40px);
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-image-3 {
        height: 180px;
        position: absolute;
        bottom: -12px;
        right: -60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      @media (max-width: 768px) {
        gap: 10px;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        zoom: 0.8;
        &-phase {
          width: 100%;
        }
        &-image-1, &-image-2, &-image-3 {
          display: none;
        }
      }
    }
  }
}

.nft {
  margin-top: 60px;
  margin-bottom: 40px;
  &-head {
    padding: 0 var(--padding-page);
    margin-bottom: 40px;
    width: calc(100% - (var(--padding-page) * 2));

    h2 {
      text-align: center;
      color: var(--text-1);
      font-size: 32px;
      line-height: 48px;
      @media (max-width: 768px) {
        text-align: left;
      }
    }

    p {
      text-align: center;
      color: var(--text-2);
      font-size: 18px;
      line-height: 32px;
      @media (max-width: 768px) {
        text-align: left;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .splide__track {
      padding: 20px 0;
    }

    &-item {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 16px;
        width: 200px;
        margin: 0 20px;
        background: var(--backgorund-page);
        text-decoration: unset;
        color: var(--text-1);
        img {
          width: 200px;
          height: 200px;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        }
      }
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-page);
  background: var(--backgorund-page);
  .links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    width: 20vw;
    a {
      color: #fff;
      cursor: pointer;
      width: 16px;
      height: 16px;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .center {
    width: 60vw;
    color: var(--text-2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .to-top {
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      border-radius: 50%;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--text-1);
      width: 16px;
      height: 16px;
      padding: 2px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .links, .center, .to-top {
      width: 100%;
      justify-content: center;
    }
  }

}

.tokenomics {
  max-width: var(--max-width-page);
  margin: 20px auto 60px;
  width: calc(100% - (var(--padding-page) * 2));
  box-shadow: 0px 0px 20px 0px #0000008c;
  border-radius: 16px;
  padding: 0 20px 20px;
  @media (max-width: 768px) {
    border-radius: 0;
    box-shadow: unset;
  }
  h2 {
    text-align: center;
    color: var(--text-1);
    font-size: 32px;
    line-height: 48px;
    @media (max-width: 768px) {
      text-align: left;
    }
  }
  &-content {
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: var(--text-2);
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    img {
      width: 100px;
      height: 100px;
    }
  }
}
